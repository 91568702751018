/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useReducer } from 'react';
import { graphql, Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faSortAlphaUp } from '@fortawesome/free-solid-svg-icons';
import { Seo } from '../../../modules/common/components/seo';
import { SectionHeader } from '../../../modules/common/components/section-header';

import lodash from 'lodash';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { INikkeCharacterNode } from '../../../modules/common/model/graphql-types';
import { NikkeSpecialitiesArray } from '../../../modules/nikke/character/model/nikke-specialities-array';

import '../../generic-page.scss';
import './harmony-cubes.scss';
import { Accordion } from 'react-bootstrap';
import { FilterBar } from '../../../modules/cs/common/components/filter-bar/filter-bar';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';

interface INikkeCharacterNodes {
  nodes: INikkeCharacterNode[];
}

interface INikkeCharacterEntry {
  allCharacters: INikkeCharacterNodes;
}

interface IProps {
  data: INikkeCharacterEntry;
}

const NikkeSkillInv: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: 'R', value: 'r' },
          { label: 'SR', value: 'sr' },
          { label: 'SSR', value: 'ssr' }
        ]
      },
      {
        key: 'element',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Electric',
            tooltip: 'Electric',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/element_electric.png"
                width={20}
                alt="Electric"
              />
            )
          },
          {
            value: 'Fire',
            tooltip: 'Fire',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/element_fire.png"
                width={20}
                alt="Fire"
              />
            )
          },
          {
            value: 'Iron',
            tooltip: 'Iron',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/element_iron.png"
                width={20}
                alt="Iron"
              />
            )
          },
          {
            value: 'Water',
            tooltip: 'Water',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/element_water.png"
                width={20}
                alt="Water"
              />
            )
          },
          {
            value: 'Wind',
            tooltip: 'Wind',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/element_wind.png"
                width={20}
                alt="Wind"
              />
            )
          }
        ]
      },
      {
        key: 'weapon',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Assault Rifle',
            tooltip: 'Assault Rifle',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/weapon_ar.png"
                width={40}
                alt="Assault"
              />
            )
          },
          {
            value: 'Minigun',
            tooltip: 'Minigun',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/weapon_minigun.png"
                width={40}
                alt="Minigun"
              />
            )
          },
          {
            value: 'Rocket Launcher',
            tooltip: 'Rocket Launcher',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/weapon_rocket.png"
                width={40}
                alt="Rocket Launcher"
              />
            )
          },
          {
            value: 'Shotgun',
            tooltip: 'Shotgun',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/weapon_shotgun.png"
                width={40}
                alt="Shotgun"
              />
            )
          },
          {
            value: 'SMG',
            tooltip: 'SMG',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/weapon_smg.png"
                width={40}
                alt="SMG"
              />
            )
          },
          {
            value: 'Sniper Rifle',
            tooltip: 'Sniper Rifle',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/weapon_sniper.png"
                width={40}
                alt="Sniper Rifle"
              />
            )
          }
        ]
      },
      {
        key: 'class',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Attacker',
            tooltip: 'Attacker',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/class_attacker.png"
                width={20}
                alt="Attacker"
              />
            )
          },
          {
            value: 'Defender',
            tooltip: 'Defender',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/class_defender.png"
                width={20}
                alt="Defender"
              />
            )
          },
          {
            value: 'Supporter',
            tooltip: 'Supporter',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/class_support.png"
                width={20}
                alt="Supporter"
              />
            )
          }
        ]
      },
      {
        key: 'burst',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: '1',
            tooltip: 'Burst I',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/type_1.png"
                width={20}
                alt="Type I"
              />
            )
          },
          {
            value: '2',
            tooltip: 'Burst II',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/type_2.png"
                width={20}
                alt="Type II"
              />
            )
          },
          {
            value: '3',
            tooltip: 'Burst III',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/type_3.png"
                width={20}
                alt="Type III"
              />
            )
          }
        ]
      },
      {
        key: 'manufacturer',
        type: 'dropdown',
        values: [
          { label: 'Any Manufacturer', value: 'all' },
          {
            label: 'Abnormal',
            value: 'abnormal'
          },
          {
            label: 'Elysion',
            value: 'elysion'
          },
          {
            label: 'Missilis',
            value: 'missilis'
          },
          {
            label: 'Pilgrim',
            value: 'pilgrim'
          },
          {
            label: 'Tetra',
            value: 'tetra'
          }
        ]
      },
      { key: 'speciality', type: 'dropdown', values: NikkeSpecialitiesArray },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.rarity.toLowerCase() === activeFilters.rarity.toLowerCase()
        );
      }
      if (activeFilters.element && activeFilters.element !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.element.indexOf(activeFilters.element) > -1
        );
      }
      if (activeFilters.weapon && activeFilters.weapon !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.weapon.toLowerCase() === activeFilters.weapon.toLowerCase()
        );
      }
      if (activeFilters.class && activeFilters.class !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.class.toLowerCase() === activeFilters.class.toLowerCase()
        );
      }
      if (activeFilters.manufacturer && activeFilters.manufacturer !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.manufacturer.toLowerCase() ===
            activeFilters.manufacturer.toLowerCase()
        );
      }
      if (activeFilters.burst && activeFilters.burst !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.burstType.toLowerCase() === activeFilters.burst.toLowerCase()
        );
      }
      if (activeFilters.speciality && activeFilters.speciality !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.specialities &&
            emp.specialities.length > 0 &&
            emp.specialities.indexOf(activeFilters.speciality) > -1
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.name > b.name ? 1 : -1));
    } else if (sortOption === '-fullName') {
      filtered = filtered.sort((a, b) => (a.name < b.name ? 1 : -1));
    }

    setVisibleCharacters(filtered);
    forceUpdate();
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [
    { value: '+fullName', label: 'Sort by A-Z' },
    { value: '-fullName', label: 'Sort by Z-A' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    // eslint-disable-next-line prefer-destructuring
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout className={'generic-page characters-page'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Skill Investment</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_skills.jpg"
            alt="Skill Investment"
          />
        </div>
        <div className="page-details">
          <h1>Skill Investment</h1>
          <h2>
            How much you should invest into Skills on every character available
            in NIKKE.
          </h2>
          <p>
            Last updated: <strong>12/09/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297004"></div>
      <SectionHeader title="Skill Investment" />
      <Accordion className="tier-list-accordion skills">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Glossary</Accordion.Header>
          <Accordion.Body>
            <p>
              Here's the explanation for all the numbers and colors used in the
              guide:
            </p>
            <ul>
              <li>
                <strong>10/5/7</strong> means upgrading Skill 1 to lvl 10, Skill
                2 to lvl 5, and Burst Skill to lvl 7,
              </li>
              <li>
                <strong className="start">Yellow</strong> means the immediate
                investment when u get the unit. There are 3 reasons for this:
              </li>
              <ul>
                <li>
                  If the unit is Meta or High Priority, it will have a starting
                  investment of more than 1/1/1 (such as 4/4/4) depending on
                  what the unit provides.
                </li>
                <li>
                  If the unit is Medium or Low Priority, 1/1/1 will be
                  mentioned, meaning no immediate investment is required, and
                  you can invest in this character if you need to use it
                  according to the priority listed.
                </li>
                <li>
                  If we deem that a unit does not have usable skills to be meta
                  for any gamemode, no investment will be mentioned.
                </li>
              </ul>
              <li>
                <strong className="min">Blue</strong> means minimum investment
                to take advantage of the Nikke's skills. There are 2 reasons for
                this:
              </li>
              <ul>
                <li>
                  Sometimes, minimum investments are breakpoints such as for
                  Noir or SAnis.
                </li>
                <li>
                  Sometimes, minimum investments are just the ideal way to
                  progress to upgrade that unit, before moving on to Recommended
                  and High.
                </li>
              </ul>
              <li>
                <strong className="rec">Red</strong> means recommended
                investment for F2P players,
              </li>
              <li>
                <strong className="high">Purple</strong> means maximum
                recommended investment, in order to get the best out of the
                unit. You should only try to reach these investments if you have
                a surplus of Skill Books leftover.
              </li>
            </ul>
            <p>More info:</p>
            <ul>
              <li>
                The skill investment list below features a lot of Level 4 and 7
                because they are easier to understand. Any higher levels will
                cost a new type of resource.
              </li>
              <li>
                However, you may choose to upgrade to Level 5 if you need a
                stronger effect instead of 4, as the increase in Blue Manual
                cost from Level 4 to 5 is still low. The leap only starts from
                Level 6 onward.
              </li>
              <li>
                There is also a leap from Level 3 to 4, so if you want to save a
                tiny bit of Blue Manuals, then leave at 3 instead of 4.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Priority</Accordion.Header>
          <Accordion.Body>
            <p>Priority explained:</p>
            <ul>
              <li>
                <strong>Meta</strong>: Most Important Nikkes.
              </li>
              <li>
                <strong>High</strong>: Good in various PvE content.
              </li>
              <li>
                <strong>Medium</strong>: Good in at least one PvE content.
              </li>
              <li>
                <strong>Low</strong>: Can perform well but not as good as other
                units / Situationally Meta / Old Meta
              </li>
              <li>
                <strong>Very Low</strong>: Niche usage / Tribe Tower Units
              </li>
              <li>
                <strong>Late Game</strong>: These nikkes need investments to
                reach their potential but are expensive to build. They should be
                invested after clearing Ch 23 Normal with mentioned priority.
              </li>
              <li>
                <strong>PvP</strong>: These units are more used for PvP.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="employees-filter-bar nikke">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <div className="nikke-skills">
        <div className="nikke-skills-row header">
          <div className="column character">Character</div>
          <div className="column suggestions pve">PVE Investment</div>
          <div className="column suggestions pvp">PVP Investment</div>
          <div className="column priority">Priority</div>
        </div>
        {visibleCharacters.map((emp, index) => {
          return (
            <div className="nikke-skills-row data" key={index}>
              <NikkeSkill char={emp.slug} />
            </div>
          );
        })}
      </div>
      {visibleCharacters.length === 0 && (
        <div className="no-results">
          <StaticImage
            src="../../../images/counterside/no_results.png"
            alt="No results"
          />
          <p>No characters found. Try changing your filter.</p>
        </div>
      )}
    </DashboardLayout>
  );
};

export default NikkeSkillInv;

export const Head: React.FC = () => (
  <Seo
    title="Skill Investment | NIKKE | Prydwen Institute"
    description="How much you should invest into Skills on every character available in NIKKE."
    game="nikke"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulNikkeCharacter(
      filter: { isReviewPending: { ne: true } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        id
        updatedAt
        createdAt
        unitId
        name
        slug
        rarity
        element
        weapon
        class
        burstType
        manufacturer
        squad
        specialities
        isNew
        isReviewPending
      }
    }
  }
`;
